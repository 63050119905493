import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import BasicDetails from "../../Tools/BasicTable";
import { getAccountDetails } from "../../../Reducer/userAccount/accountDetailsSlice";
import {
  issueOption,
  reasonsToDelist,
  basicDetailsCols,
  advertsCols,
  transactionsCols,
} from "../../../data/data";
import AdvertList from "./adverts";
import Transcation from "./transcation";
import { fetchView, fetchDelete } from "../../Functions/DataTable";
import {
  delistAccount,
  getStatusList,
  sendEmail,
  toggleAdDisplay,
  trasncationDeleteFun,
  updateProfile,
} from "../../../services/api";
import BoostrapModel from "../../Tools/BoostrapModel";
import ReasonModel from "../../Tools/ReasonModel";
import swal from "sweetalert";
import { DELIST_MAIL_SUBJECT } from "../../../data/varible";
import Skeletons1 from "../../Tools/Skeletons/Skeletons1";
import KycBox from "../../Tools/KycBox";
import KycModel from "../singlePendingApprovalUser/model";
import { typeToLabel } from "../../../data/utils";
import BackButton from "../../Tools/BackButton";
import { Icon } from "@iconify/react/dist/iconify.js";
import ChangeType from "./changeType";
import VerifyContact from "./verifyContact";
import DeleteAccountPermanentally from "./deleteAccountPermanentally";

const AccountDetails = () => {
  const [action, setAction] = React.useState([]);
  const [actionTrans, setActionTrans] = React.useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [show1, setShow1] = React.useState(false);
  const [modalData1, setModalData1] = React.useState({});
  const account = useSelector((state) => state.accountDetails);
  const userType = useSelector((state) => state.accountDetails.userType);
  const submitting = useSelector((state) => state.accountDetails.submitting);
  const [loading, setLoading] = useState(false);
  const [loadingWithoutMail, setLoadingWithoutMail] = useState(false);
  const [statusList, setStatusList] = useState();
  const [recallAds, setRecallAds] = useState(1);
  const [notes, setNotes] = useState(
    account?.details?.details?.adminNotes?.notes
  );
  const mainUTypes = ["dealer", "brand"];
  const [show, setShow] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [state, setState] = React.useState({
    id: "",
    label: "",
    heading: "",
    buttonTitle: "",
    option: [],
    type: "",
    modalType: "",
    notes: [],
  });

  const openModal1 = (data) => {
    setModalData1(data);
    setShow1(true);
  };
  const handleCloseModal1 = () => {
    setShow1(false);
    setTimeout(() => {
      setModalData1({});
    }, 300);
  };
  useEffect(() => {
    setNotes(account?.details?.details?.adminNotes?.notes);
  }, [account]);
  let basicColumns =
    basicDetailsCols?.[account?.userType] || basicDetailsCols?.["dealer"];
  let advertColumns =
    advertsCols?.[account?.userType] || advertsCols?.["dealer"];
  let transactionsColumns =
    transactionsCols?.[account?.userType] || transactionsCols?.["dealer"];

  const [params] = useSearchParams();
  const userId = params.get("id");
  const body = {
    id: userId,
  };
  const getData = () => {
    dispatch(getAccountDetails(body));
    getStatusList().then((res) => {
      setStatusList(res?.data);
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    getData();
    fetchActionsTrans();
  }, [dispatch, userId]);

  const handleSubmit = (data) => {
    setLoading(true);
    let formData = {};
    switch (state?.modalType) {
      case "addNote":
        const updateNotes = {
          userId: state?.id,
          adminNotes: { notes: state?.notes },
        };
        updateProfile(updateNotes).then((res) => {
          if (res?.status) {
            getData();
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
      case "warning":
        formData = {
          subject: data?.title,
          message: data?.body,
          userIds: [userId],
          type: "0",
          attachments: selectedFile.map((v) => v?.s3URL),
        };
        sendEmail(formData).then((res) => {
          if (res.status) {
            swal("Success", res?.message, "success");
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
      case "delist":
        const updateData = {
          userId: state?.id,
          allowedToLogin: !state?.allowedToLogin,
          isAdImageUploaded: "YES",
        };
        delistAccount({ id: state?.id }).then((res) => {
          if (res?.status) {
            getData();
            setRecallAds((recallAds) => recallAds + 1);
            navigate("/user_accounts");
            formData = {
              subject: state?.allowedToLogin
                ? DELIST_MAIL_SUBJECT + data?.reason
                : data?.title,
              message: data?.body,
              userIds: [userId],
              type: "0",
              attachments: selectedFile.map((v) => v?.s3URL),
            };
            sendEmail(formData);
            swal("Success", res?.message, "success");
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        // updateProfile(updateData).then((res) => {
        //   if (res?.status) {
        //     formData = {
        //       subject: state?.allowedToLogin
        //         ? DELIST_MAIL_SUBJECT + data?.reason
        //         : data?.title,
        //       message: data?.body,
        //       userIds: [userId],
        //       type: "0",
        //       attachments: selectedFile.map((v) => v?.s3URL),
        //     };
        //     sendEmail(formData);
        //     swal("Success", res?.message, "success");
        //     setShow(false);
        //   } else {
        //     swal("Error", res?.message, "error");
        //   }
        //   setLoading(false);
        // });
        break;
    }
  };

  const delistRelistWithoutMail = () => {
    setLoadingWithoutMail(true);
    const updateData = {
      userId: state?.id,
      allowedToLogin: !state?.allowedToLogin,
      isAdImageUploaded: "YES",
    };
    delistAccount({ id: state?.id }).then((res) => {
      if (res?.status) {
        getData();
        setRecallAds((recallAds) => recallAds + 1);
        navigate("/user_accounts");
        swal("Success", res?.message, "success");
        setShow(false);
      } else {
        swal("Error", res?.message, "error");
      }
      setLoadingWithoutMail(false);
    });
    // updateProfile(updateData).then((res) => {
    // if (res?.status) {
    //   getData();
    //   swal("Success", res?.message, "success");
    //   setShow(false);
    // } else {
    //   swal("Error", res?.message, "error");
    // }
    // setLoadingWithoutMail(false);
    // });
  };

  const fetchActionsTrans = async () => {
    const edit = fetchView(
      "Edit",
      getAccountDetails,
      "account_details",
      dispatch,
      navigate,
      "",
      "view"
    );
    const viewNoraml = fetchView(
      "View",
      getAccountDetails,
      "account_details",
      dispatch,
      navigate,
      "",
      "view"
    );
    const deleted = fetchDelete(
      "Delete",
      trasncationDeleteFun,
      dispatch,
      "",
      getAccountDetails
    );
    setActionTrans([viewNoraml, edit, deleted]);
  };

  const basicRows = {
    ...account?.details?.details,
    about: account?.details?.details?.additionalInfo?.about,
    gender: account?.details?.details?.gender?.[0]?.name?.en,
  };
  const render = {
    verify:
      !account?.details?.details?.phoneNumberVerified ||
      !account?.details?.details?.emailVerified,
  };
  return (
    <div class="overview account_details">
      <h6 class="back d-flex justify-content-between mb-4">
        <BackButton />
      </h6>
      <div className="d-flex justify-content-between align-items-center  mb-4 ">
        <div className="fs-3 fw-bold">{`Account details (${typeToLabel(
          account?.userType
        )})`}</div>
        <div>
          <button
            type="button"
            className={`themeButton m-0`}
            style={{ width: "100px" }}
            onClick={() =>
              openModal1({
                heading: "Change account type",
                component: (
                  <ChangeType
                    account={account?.details?.details}
                    refreshPage={getData}
                    handleCloseModal={handleCloseModal1}
                  />
                ),
              })
            }
          >
            Change type
          </button>
        </div>
      </div>

      {account?.details?.details?.allowedToLogin && (
        <div className="d-flex justify-content-end align-items-center gap-2">
          {render?.verify && (
            <div className="col mb-2 d-flex gap-2">
              <button
                type="button"
                className={`themeButton m-0 d-flex justify-content-between align-items-center `}
                style={{ width: 120 }}
                onClick={() =>
                  openModal1({
                    heading: "Verify contact information",
                    component: (
                      <VerifyContact
                        account={account?.details?.details}
                        refreshPage={getData}
                        handleCloseModal={handleCloseModal1}
                      />
                    ),
                  })
                }
              >
                Verify contact
                <Icon
                  icon="gg:check-o"
                  width="20"
                  height="20"
                  style={{ color: "white" }}
                />
              </button>
            </div>
          )}
          <div className="col-3 col-lg-2 mb-2 d-flex justify-content-end">
            <button
              type="button"
              className={` ms-0 themeButtonNo`}
              style={{ width: 120 }}
              onClick={() => {
                const type = account?.details?.details?.allowedToLogin;
                setState({
                  id: userId,
                  heading: type ? "Delist account ?" : "Relist account ?",
                  title: type ? "delisting account" : "relisting account",
                  buttonTitle: type ? "Delist" : "Relist",
                  option: reasonsToDelist,
                  type: "selection",
                  modalType: "delist",
                  allowedToLogin: type,
                });
                setShow(!show);
              }}
            >
              {"Delist"}
            </button>
          </div>
          <div className="col-4 col-lg-2 mb-2">
            <button
              type="button"
              className=" w-100 ms-0 themeButtonYes"
              onClick={() => {
                setState({
                  id: userId,
                  title: "Issue Warning",
                  heading: "Issue Warning ?",
                  buttonTitle: "Submit",
                  option: issueOption,
                  type: "selection",
                  modalType: "warning",
                });
                setShow(!show);
              }}
            >
              Issue warning
            </button>
          </div>
        </div>
      )}
      <div class="tab-content mt-4" id="myTabContent">
        {submitting ? (
          <>
            <Skeletons1 lines={2} />
          </>
        ) : (
          <BasicDetails
            heading={"Basic Details"}
            column={basicColumns || []}
            row={basicRows || []}
            parentStyle={"set_list"}
            childStyle={"col-6 col-lg-4 col-xl-4 mb-4"}
          />
        )}
        {!submitting && (
          <div className="set_list">
            <div className={`d-flex justify-content-between`}>
              <h3 style={{ marginBottom: "0px" }}>Admin notes</h3>
              {/* {account?.details?.details?.adminNotes?.notes?.length > 0 && (
              )} */}
              <span className="me-2">
                <button
                  type="button"
                  className="themeButtonYes  w-100"
                  onClick={() => {
                    setState({
                      id: userId,
                      title: "Add note",
                      heading: "Add note",
                      buttonTitle: "Save",
                      option: [],
                      type: "note",
                      modalType: "addNote",
                      notes: account?.details?.details?.adminNotes?.notes || [],
                    });
                    setShow(!show);
                  }}
                >
                  {account?.details?.details?.adminNotes?.notes?.length > 0
                    ? "Edit notes"
                    : "Add notes"}
                </button>
              </span>
            </div>
            <ul style={{ listStyleType: "none", padding: "20px 0px 0px 0px" }}>
              {notes?.map((v, i) => {
                return (
                  <li
                    className="d-flex justify-content-between p-2 rounded border mb-1"
                    key={i}
                  >
                    <span>{v}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {mainUTypes.includes(account?.userType) && (
          <div class={"w-100"}>
            <div
              class={
                "Documents d-block d-lg-flex justify-content-between align-items-center p-4"
              }
            >
              <h6
                style={{ fontWeight: 700, fontSize: "24px", color: "#2B2B2B" }}
              >
                <span>KYC documents</span> <br />{" "}
                <span className="fw-semibold fs-5">
                  {statusList &&
                    statusList.filter(
                      (v) => v?._id === account?.details?.details?.kyc
                    )?.[0]?.name?.en}
                </span>
              </h6>
              {account?.details?.details?.additionalInfo?.dealerKycDocuments
                ?.length > 0 && (
                <p
                  role="button"
                  onClick={() =>
                    openModal1({
                      heading: `Kyc for ${account?.details?.details?.dealerShipName}`,
                      style: "warning warningKyc",
                      component: <KycModel row={account?.details?.details} />,
                    })
                  }
                >
                  Documents
                  <a>
                    <img src={"../assets/images/eye2.png"} alt="eye" />
                  </a>
                </p>
              )}
            </div>
          </div>
        )}
        <AdvertList
          columns={advertColumns}
          // rows={activeAdvertRows || []}
          action={action}
          userId={userId}
          state={state}
          setState={setState}
          setShow={setShow}
          recallAds={recallAds}
        />
        <Transcation
          columns={transactionsColumns}
          action={actionTrans}
          userId={userId}
        />
        <div className="d-flex justify-content-end">
          <button
            className="themeButtonNo m-0"
            style={{ width: 300, fontSize: 15 }}
            onClick={() =>
              openModal1({
                heading: "Are you sure you want to delete this account!!",
                component: (
                  <DeleteAccountPermanentally
                    account={account?.details?.details}
                    handleCloseModal={handleCloseModal1}
                    callback={() => {
                      navigate(-1)
                    }}
                  />
                ),
              })
            }
          >
            DELETE ACCOUNT PERMANENTALLY
          </button>
        </div>
        <BoostrapModel
          show={show}
          heading={state.heading}
          onHide={() => setShow(!show)}
          style={"w-100"}
          component={
            <ReasonModel
              id={state.id}
              label={state.title}
              buttonTitle={state.buttonTitle}
              option={state.option}
              type={state.type}
              data={state}
              setData={setState}
              handleSubmitEvent={handleSubmit}
              onHide={() => setShow(!show)}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              loading={loading}
              loadingWithoutMail={loadingWithoutMail}
              continueWithoutCallback={delistRelistWithoutMail}
            />
          }
        />
        <BoostrapModel
          show={show1}
          heading={modalData1?.heading || ""}
          style={modalData1?.style || ""}
          component={modalData1?.component || <></>}
          onHide={() => handleCloseModal1()}
        />
      </div>
    </div>
  );
};

export default AccountDetails;

import React, { useEffect, useState } from "react";
import Button from "../../Tools/Button";
import FormInput from "../../Tools/FormInput";

//form imports
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  sendEmail,
  sendPushNotification,
  sendPushNotificationToUnregistered,
  sendSMS,
} from "../../../services/api";
import TextareaWithAttatch from "./TextareaWithAttatch";
import swal from "sweetalert";

import SelectedAccounts from "./selectedAccounts";
import { translateInTwoLanguages } from "../../../data/utils";
import { Input } from "reactstrap";
import CopyKeywords from "../../Tools/CopyKeywords";

const NotificationMessage = ({
  heading,
  allFilter,
  setAllFilter,
  getName,
  selectUnselectUser,
}) => {
  const pageContent = {
    app: {
      hasTitle: true,
      hasAttatch: false,
      titlePlaceholder: "Write your notification title",
      titlePlaceholder_ar: "اكتب عنوان الإشعار الخاص بك",
      titlePlaceholder_ku: "ناونیشانی ئاگادارکردنەوەکەت بنووسە",
      bodyPlaceholder: "Write your notification content...",
      bodyPlaceholder_ar: "اكتب محتوى الإشعار الخاص بك...",
      bodyPlaceholder_ku: "ناوەڕۆکی ئاگادارکردنەوەکانت بنووسە...",
    },
    phone: {
      hasTitle: false,
      hasAttatch: false,
      titlePlaceholder: "Write your SMS title",
      titlePlaceholder_ar: "اكتب عنوان رسالتك النصية القصيرة",
      titlePlaceholder_ku: "ناونیشانی کورتەنامەکەت بنووسە",
      bodyPlaceholder: "Write your SMS content...",
      bodyPlaceholder_ar: "اكتب محتوى رسالتك النصية القصيرة...",
      bodyPlaceholder_ku: "ناوەڕۆکی کورتەنامەکانت بنووسە...",
    },
    email: {
      hasTitle: true,
      hasAttatch: true,
      titlePlaceholder: "Write your e-mail subject",
      titlePlaceholder_ar: "اكتب موضوع بريدك الإلكتروني",
      titlePlaceholder_ku: "بابەتی ئیمەیڵەکەت بنووسە",
      bodyPlaceholder: "Write your e-mail content...",
      bodyPlaceholder_ar: "اكتب محتوى بريدك الإلكتروني...",
      bodyPlaceholder_ku: "ناوەڕۆکی ئیمەیڵەکەت بنووسە...",
    },
  };
  // const [captchaCode, setCaptchaCode] = useState("");
  const toBulk =
    allFilter?.users?.length === 0 &&
    allFilter?.sendTo === "4" &&
    allFilter?.sendOn === "app"
      ? true
      : false;

  const [yes, no] = [
    Yup.string().required("This field is required"),
    Yup.string(),
  ];
  const noTitle = {
    title: no,
    body: yes,
  };
  const off = {
    title: no,
    body: no,
  };
  const on = {
    title: yes,
    body: yes,
  };

  const createSchema = () => {
    let en = on;
    let ar = on;
    let ku = on;
    if (!pageContent?.[allFilter?.sendOn]?.hasTitle) {
      [en, ar, ku] = [noTitle, noTitle, noTitle];
    }
    if (toBulk) {
      [en, ar, ku] = [off, off, off];
    }

    const schema = Yup.object().shape({
      title: en.title,
      body: en.body,
      title_ar: ar.title,
      body_ar: ar.body,
      title_ku: ku.title,
      body_ku: ku.body,
    });

    return schema;
  };
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createSchema()),
    mode: "onBlur",
  });
  useEffect(() => {
    setValue("title", "");
    setValue("body", "");
    setValue("title_ar", "");
    setValue("body_ar", "");
    setValue("title_ku", "");
    setValue("body_ku", "");
  }, [allFilter?.sendOn]);

  const [selectedFile, setSelectedFile] = React.useState([]);
  const [selectedFile_ar, setSelectedFile_ar] = React.useState([]);
  const [selectedFile_ku, setSelectedFile_ku] = React.useState([]);
  const [otherLangs, setOtherLangs] = useState({
    view: false,
    loading: false,
  });

  const onSubmit = (data) => {
    let formData = {};
    if (toBulk) {
      const { title, body, title_ar, body_ar, title_ku, body_ku } = data;
      if ((title && body) || (title_ar && body_ar) || (title_ku && body_ku)) {
        sendPushNotificationToUnregistered({
          title: title_ar || title_ku || title,
          body: body_ar || body_ku || body,
        }).then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
          } else {
            swal("Error", "Something went wrong!", "error");
          }
        });
      } else {
        swal("Field missing", "Fill in at least one language", "info");
      }
      return;
    }
    const type = allFilter.users.length > 0 ? "0" : allFilter.sendTo;
    switch (allFilter.sendOn) {
      case "app":
        formData = {
          userIds: allFilter?.users,
          type: type,
          title: data?.title,
          body: data?.body,
          title_ar: data?.title_ar,
          body_ar: data?.body_ar,
          title_ku: data?.title_ku,
          body_ku: data?.body_ku,
          // token: captchaCode
        };
        sendPushNotification(formData).then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
          } else {
            swal("Error", "Something went wrong!", "error");
          }
        });
        break;
      case "phone":
        formData = {
          userIds: allFilter.users,
          message: data?.body,
          message_ar: data?.body_ar,
          message_ku: data?.body_ku,
          type: type,
          // token: captchaCode,
        };
        sendSMS(formData).then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
          } else {
            swal("Error", "Something went wrong!", "error");
          }
        });
        break;
      case "email":
        formData = {
          type: type,
          userIds: allFilter?.users,
          subject: data?.title,
          message: data?.body,
          attachments: selectedFile?.map((v) => v.s3URL),
          subject_ar: data?.title_ar,
          message_ar: data?.body_ar,
          attachments_ar: selectedFile_ar?.map((v) => v.s3URL),
          subject_ku: data?.title_ku,
          message_ku: data?.body_ku,
          attachments_ku: selectedFile_ku?.map((v) => v.s3URL),
        };
        sendEmail(formData).then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
          } else {
            swal("Error", "Something went wrong!", "error");
          }
        });
        break;
    }
  };

  // const handleCaptureCaptcha = (code) => {
  //   console.log(code);
  //   setCaptchaCode(code);
  // };

  const previewOtherLangs = async () => {
    let [title_ar, body_ar, title_en, body_en, title_ku, body_ku] = [
      getValues("title_ar"),
      getValues("body_ar"),
      getValues("title"),
      getValues("body"),
      getValues("title_ku"),
      getValues("body_ku"),
    ];
    let [title, body, lang] = ["", "", ""];
    if (title_ar?.length > 0 || body?.length > 0) {
      title = title_ar;
      body = body_ar;
      lang = "ar";
    } else if (title_en?.length > 0 || body_en?.length > 0) {
      title = title_en;
      body = body_en;
      lang = "en";
    } else if (title_ku?.length > 0 || body_ku?.length > 0) {
      title = title_ku;
      body = body_ku;
      lang = "ku";
    }
    if (title?.length > 0 || body?.length > 0) {
      setOtherLangs({ ...otherLangs, loading: true });
      const translations = await Promise.all([
        translateInTwoLanguages(lang, title),
        translateInTwoLanguages(lang, body),
      ]);
      title = translations[0];
      body = translations[1];
      setValue("title", title?.en || "");
      setValue("body", body?.en || "");
      setValue("title_ar", title?.ar || "");
      setValue("body_ar", body?.ar || "");
      setValue("title_ku", title?.ku || "");
      setValue("body_ku", body?.ku || "");
      setOtherLangs({
        view: true,
        loading: false,
      });
    } else {
      setOtherLangs({ ...otherLangs, loading: false, view: false });
    }
  };

  const copyWordsList = ["Ako Sayara", "اكو سيارة", "ئەکو سەیارە"];

  return (
    <div className="setin">
      <h3>{heading}</h3>
      <div style={{ marginBottom: 10 }}>
        <h6 className="mb-3">Copy text</h6>
        <CopyKeywords list={copyWordsList} />
      </div>
      {/* {!toBulk && (
      )} */}
      <div className="d-flex justify-content-end">
        <a
          dir="ltr"
          className={`themeButton px-2 py-1`}
          onClick={() => previewOtherLangs()}
          style={{
            fontSize: "14px",
            fontWeight: 500,
            textDecoration: "none",
            height: "unset",
            width: "unset",
          }}
        >
          {otherLangs?.loading ? "Translating..." : "Translate"}
        </a>
      </div>

      <div
        className=" d-flex flex-column gap-3"
        style={{ borderRadius: "8px" }}
      >
        <div className="ar" dir="rtl">
          <div className="d-flex flex-column gap-3">
            <h6
              className="text-decoration-underline m-0 text-center"
              style={{ fontSize: "18px" }}
            >
              Arabic
            </h6>
            {pageContent?.[allFilter.sendOn]?.hasTitle && (
              <div>
                <FormInput
                  intype={"text"}
                  event={register}
                  name={"title_ar"}
                  style={"form-control"}
                  placeholder={
                    pageContent?.[allFilter.sendOn]?.titlePlaceholder_ar
                  }
                  inlineStyle={{ direction: "rtl" }}
                />
                {errors.title_ar && (
                  <p className="helperText">{errors.title_ar.message}</p>
                )}
              </div>
            )}

            <TextareaWithAttatch
              id={"mailInput_ar"}
              rtl={true}
              selectedFile={selectedFile_ar}
              setSelectedFile={setSelectedFile_ar}
              control={control}
              errors={errors}
              textareaPlaceholder={
                pageContent?.[allFilter.sendOn]?.bodyPlaceholder_ar
              }
              showAttatch={pageContent?.[allFilter.sendOn]?.hasAttatch}
              textareaHeight={150}
              attatchStyles={{
                top: "106px",
                left: "12px",
                position: "absolute",
              }}
              name={"body_ar"}
            />
          </div>
        </div>
        {/* {!toBulk && (
        )} */}
        <div className="ku" dir="rtl">
          <div className="d-flex flex-column gap-3">
            <h6
              className="text-decoration-underline m-0 text-center"
              style={{ fontSize: "18px" }}
            >
              Kurdish
            </h6>
            {/* <h6>Title</h6> */}
            {pageContent?.[allFilter.sendOn]?.hasTitle && (
              <div>
                <FormInput
                  intype={"text"}
                  event={register}
                  name={"title_ku"}
                  style={"form-control"}
                  placeholder={
                    pageContent?.[allFilter.sendOn]?.titlePlaceholder_ku
                  }
                />
                {errors.title_ku && (
                  <p className="helperText">{errors.title_ku.message}</p>
                )}
              </div>
            )}

            {/* <h6 className="mt-3">Body</h6> */}
            <TextareaWithAttatch
              id={"mailInput_ku"}
              rtl={true}
              selectedFile={selectedFile_ku}
              setSelectedFile={setSelectedFile_ku}
              control={control}
              name={"body_ku"}
              errors={errors}
              textareaPlaceholder={
                pageContent?.[allFilter.sendOn]?.bodyPlaceholder_ku
              }
              showAttatch={pageContent?.[allFilter.sendOn]?.hasAttatch}
              textareaHeight={150}
              attatchStyles={{
                top: "106px",
                left: "12px",
                position: "absolute",
              }}
            />
          </div>
        </div>
        {/* {!toBulk && (
        )} */}
        <div className="en" dir="ltr">
          <div className="d-flex flex-column gap-3">
            <h6
              className="text-decoration-underline m-0 text-center"
              style={{ fontSize: "18px" }}
            >
              English
            </h6>
            {/* <h6>Title</h6> */}
            {pageContent?.[allFilter.sendOn]?.hasTitle && (
              <div>
                <FormInput
                  intype={"text"}
                  event={register}
                  name={"title"}
                  style={"form-control"}
                  placeholder={
                    pageContent?.[allFilter.sendOn]?.titlePlaceholder
                  }
                />
                {errors.title && (
                  <p className="helperText">{errors.title.message}</p>
                )}
              </div>
            )}

            {/* <h6 className="mt-3">Body</h6> */}
            <TextareaWithAttatch
              id={"mailInput_en"}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              control={control}
              errors={errors}
              textareaPlaceholder={
                pageContent?.[allFilter.sendOn]?.bodyPlaceholder
              }
              showAttatch={pageContent?.[allFilter.sendOn]?.hasAttatch}
              textareaHeight={150}
              attatchStyles={{
                top: "106px",
                left: "12px",
                position: "absolute",
              }}
              name={"body"}
            />
          </div>
        </div>
      </div>

      <SelectedAccounts
        className={"mt-3 d-none d-xl-block"}
        allFilter={allFilter}
        setAllFilter={setAllFilter}
        selectUnselectUser={selectUnselectUser}
        getName={getName}
      />

      <form className="intype">
        {" "}
        {/* Use className instead of class */}
        <Button
          // disabled={
          //   allFilter?.users?.length === 0 || !allFilter?.sendTo
          // }
          type={"button"}
          title={<img src="assets/images/send.png" alt="img" />}
          onClick={handleSubmit(onSubmit)}
        />
      </form>
    </div>
  );
};

export default NotificationMessage;
